<template>
  <div class="mobile-container">
    <mobileStatusBar mode="gray" title="添加工单" />
    <div class="column-item">
      <div class="title-item">工单类型</div>
      <div class="class-item">
        <div class="item" :class="item.Id == classId ? 'active' : ''" v-for="(item, index) in classList" :key="index" @click="handleChooseClass(item.Id)">{{ item.CnClassName }}</div>
      </div>
    </div>
    <div class="column-item">
      <div class="title-item">工单类型</div>
      <div class="textarea">
        <textarea v-model="desc" maxlength="200" rows="6" placeholder="请输入详细问题意见"></textarea>
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="submit-btn public-btn" @click="handleSubmit">
      <van-loading v-if="submiting" color="#ffffff" />
      <span v-else>提交</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "AddGd",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      classList: [],
      classId: 0,
      desc: '',
      submiting: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {
    this.handleGetClassList()
  },
  methods: {
    handleChooseClass(id) {
      this.classId = id
    },
    async handleGetClassList() {
      var res = await this.$API.announcedCategoryList.post()
      if (res.info && res.info.length > 0) {
        this.classId = res.info[0].Id
      }
      this.classList = res.info
    },
    async handleSubmit() {
      const { classId, desc, submiting } = this
      if (submiting) {
        return
      }
      if (!desc) {
        this.$toast('请输入详细问题意见')
        return
      }
      const params = {
        Switch: 1,
        Desc: desc,
        ClassId: classId
      }
      this.submiting = true
      var res = await this.$API.addTickets.post(params)
      this.submiting = false
      if (res.error == 0) {
        this.$toast.success(res.info)
        this.$router.back()
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.column-item {
  width: 6.86rem;
  margin-top: .32rem;
  background-color: #fff;
  border-radius: .16rem;
  .title-item {
    position: relative;
    height: .4rem;
    line-height: .4rem;
    margin: .24rem;
    padding-left: .18rem;
    &::after {
      content: '*';
      color: #ff2442;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .class-item {
    padding-left: .24rem;
    .item {
      display: inline-block;
      height: .7rem;
      line-height: .7rem;
      text-align: center;
      padding: 0 .2rem;
      margin-right: .2rem;
      margin-bottom: .16rem;
      border: .02rem solid #f2f2f2;
      border-radius: .08rem;
      &.active {
        background-color: #1399ff;
        border-color: #1399ff;
        color: #fff;
      }
    }
  }
  .textarea {
    margin: 0 .24rem .24rem;
    background-color: #f8f8f8;
    border-radius: .16rem;
    padding: .24rem;
    line-height: .4rem;
    color: #333;
  }
}
.submit-btn {
  cursor: pointer;
  width: 6.86rem;
  height: .88rem;
  text-align: center;
  line-height: .88rem;
  border-radius: .12rem;
  color: #fff;
  font-size: .3rem;
  font-weight: bold;
  margin-bottom: 1.6rem;
}
</style>